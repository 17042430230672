import React from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import Image from "./../../public/paperless-right.png";
import { Outlet } from "react-router-dom";
import { Loading } from "carbon-components-react";
export type MainLayoutProps = { loading: boolean };
export const MainLayout: React.FC<MainLayoutProps> = (
  props: MainLayoutProps
) => {
  return (
    <div className="wrapper">
      <Header />
      <main className="cms-mainpaperless-main" data-testid="main-layout">
        <div className="os-container--max-width">
          <div className="os-flex-grid os-flex--stack-mobile">
            <div className="os-flex-grid__col os-flex-grid__col--7 mb-6">
              <Loading active={props.loading}></Loading>
              <Outlet />
            </div>
            <div className="os-flex-grid__col os-flex-grid__col--5 u-hidden-mobile">
              <img src={Image} alt="Paperless"></img>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};
