import React from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Optout } from "./../../views/optout/Optout";
import { Loading } from "carbon-components-react";
import { AddressInfoModelList } from "../../models/AddressInfoModel";

export type OptoutLayoutProps = {
  ff_paperlessOptOut: boolean;
  setLoading: Function;
  loading: boolean;
};
export const OptoutLayout: React.FC<OptoutLayoutProps> = (
  props: OptoutLayoutProps
) => {
  return (
    <div className="wrapper" style={!props.ff_paperlessOptOut ? { background: "#e9edef" } : {}}>
      <Header />
      <main className="opt-main" data-testid="opt-layout" style={!props.ff_paperlessOptOut ? { background: "#e9edef" } : {}}>
        <Loading active={props.loading} />
        <Optout ff_paperlessOptOut={props.ff_paperlessOptOut} setLoading={props.setLoading} />
      </main>
      <Footer />
    </div>
  );
};