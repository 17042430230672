import axios, { AxiosResponse } from "axios";
import { AddressInfoModelList } from "../models/AddressInfoModel";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const reactAppApimUrl = process.env.REACT_APP_APIMURL;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export async function getAddressInfo(
  emailToken: string | undefined
): Promise<AxiosResponse<AddressInfoModelList>> {
  if (!emailToken) {
    console.log("email token is null");
  }
  const response = axios.get<AddressInfoModelList>(
    `${reactAppApimUrl}/address?emailToken=${emailToken}`
  );
  return response;
}

export async function enroll(
  emailToken: string | undefined
): Promise<AxiosResponse> {
  
  const response = axios.post(
    `${reactAppApimUrl}/submitenrollment`,
    {emailToken}
  );
  return response;
}

export async function trackDecline(
  email: string
): Promise<AxiosResponse> {  
  const response = axios.post(
    `${reactAppApimUrl}/paperlessOptOut`,
    {EmailAddress: email}
  );
  return response;
}