import AddressInfoModel, {
  AddressInfoModelList,
} from "../../models/AddressInfoModel";
import { EnergyIcon } from "../EnergyIcon";
type AddressInfoProps = {
  addresses: AddressInfoModelList;
  mode?: string;
  icon_color: string;
};

export const AddressInfo: React.FC<AddressInfoProps> = (props) => {
  require("./address-info.scss");
  const zipCode = (address: AddressInfoModel) => {
    return address && address.serviceAddress
      ? address.serviceAddress.substring(
          address.serviceAddress.length - 5,
          address.serviceAddress.length
        )
      : "";
  };
  return (
    <span className={"addresses-container mb-3 " + props.mode}>
      {props.addresses &&
        props.addresses.map((address, index) => (
          <p
            tabIndex={0}
            className={
              props.icon_color +
              " account-tile mb-3 o-type-expressive-p " +
              props.mode
            }
            key={index}
          >
            <EnergyIcon
              address={address}
              color={props.icon_color}
              mode={props.mode}
            ></EnergyIcon>
            <span className="text-parent">
              <span className="nickname" data-testid="serviceType">
                {address.fuelType === "GAS" ? "Gas" : "Electric"} service at
              </span>
              <span className="address-wrapper">
                <span className="address-text" data-testid="address">
                  {address && address.serviceAddress
                    ? address.serviceAddress.substring(
                        0,
                        address.serviceAddress.length - 5
                      )
                    : ""}
                </span>
                <span className="zip-code mr-4" data-testid="zip-code">
                  &nbsp;{"" + zipCode(address)}
                </span>
              </span>
            </span>
          </p>
        ))}
    </span>
  );
};
