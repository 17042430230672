import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { Button } from "carbon-components-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { enroll, trackDecline } from "../../api/UIApi";
import { Warning } from "../../assets/icons/svg";
import { AddressInfoModelList } from "../../models/AddressInfoModel";
export type ErrorProps = {
  addresses: AddressInfoModelList;
  type: string
};
export const Error: React.FC<ErrorProps> = (props: ErrorProps) => {
  require("./error-page.scss");
  const emailToken =
    props.addresses && props.addresses.length
      ? props.addresses[0].emailToken
      : undefined;

  const [enrollment] = useState({});
  const navigate = useNavigate();
  const appInsights = useAppInsightsContext();
  const trackEnrollment = useTrackEvent(appInsights, "Enrollment", enrollment);

  const retry = () => {
    if (props.type === "enrollment") {
      trackEnrollment({ emailToken: emailToken });
      enroll(emailToken)
        .then(() => navigate("/confirmation"))
        .catch(() => navigate("/error"));
    } else {           
      const url = window.location.href
      const emailAddress = url.substring(url.lastIndexOf("/") + 1)
      navigate(`/decline/${emailAddress}`);
    }
  };

  return (
    <div className="modal">
      <div className="error-component mb-6 p-8">
        <h1 className="o-heading-alpha mb-0 error-title" tabIndex={0}>
          There was a problem
        </h1>
        <div className="error-box mb-6 p-4">
          <div className="warning-icon-box">
            <Warning height={40} width={25}></Warning>
          </div>
          <div className="error-text p-5 o-type-expressive-p">
            <span data-testid="error-msg" tabIndex={0}>
              We were unable to process your request. Please try again. If the
              problem continues, please{" "}
              <a
                href="https://www.eversource.com/content/general/about/contact"
                target="_blank"
                rel="noreferrer"
                id="contactUs"
              >
                contact us
              </a>{" "}
              for assistance.
            </span>
          </div>
        </div>
        <div className="button-container">
          <div className="retry-button-box">
            <Button
              className="bx-btn bx-btn--primary u-margin-auto"
              kind="primary"
              tabIndex={0}
              onClick={retry}
              data-testid="retry-btn"
              type="submit"
            >
              Retry
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
