import { Logo } from "../../../assets";
import { REACT_APP_EVERSOURCE_URL } from "../../../variables";

type Props = {};

export const Header: React.FC<Props> = ({ children }) => {
  return (
    <header
      data-testid="header"
      className="paperless-container paperless-header"
    >
      <div className="u-tri-color-lines--before"></div>
      <div className="cms-container--max-width pt-7 pb-7 lg:pt-8 lg:pb-8">
        <div className="paperless__header-logo o-flex-grid">
          <a href={`${REACT_APP_EVERSOURCE_URL}`}>
            <Logo></Logo>
          </a>
        </div>
      </div>
    </header>
  );
};
