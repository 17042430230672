import { Info } from "../../assets/icons/svg";

type NotificationProps = {
  information: string;
};

export const Notification: React.FC<NotificationProps> = (props) => {
  require("./notification.scss");

  return (
    <div className={`c-page-notification`} role="alert">
      <div className="c-page-notification__icon storm">
        <Info></Info>
      </div>
      <div className="c-page-notification__content" tabIndex={0}>
        {props.information}
      </div>
    </div>
  );
};
