import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { REACT_APP_EVERSOURCE_URL } from "../../variables";
import parse from "html-react-parser";
import styles from "./Enrollment.module.scss";
import { AddressInfoModelList } from "../../models/AddressInfoModel";
import { enroll } from "../../api/UIApi";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import sanitizeHtml from "sanitize-html";

type EnrollmentProps = {
  addresses: AddressInfoModelList;
  setLoading: Function;
};
export function Enrollment(props: EnrollmentProps) {
  const emailToken =
    props.addresses && props.addresses.length
      ? props.addresses[0].emailToken
      : undefined;
  const [accept, setAccept] = useState(false);
  const [enrollment] = useState({});
  const navigate = useNavigate();
  const terms =
    REACT_APP_EVERSOURCE_URL +
    "/security/account/viewtermsandconditions#Paperless";
  const checkBoxText = "I accept all ";
  const checkboxUrl = sanitizeHtml(
    ` <a href='${terms}' target='_blank'>paperless billing terms and conditions</a>`
  );
  const buttonText = "Sign Up For Paperless Billing";
  const appInsights = useAppInsightsContext();
  const trackEnrollment = useTrackEvent(appInsights, "Enrollment", enrollment);
  const handleOnChange = () => {
    setAccept(!accept);
  };
  const signUp = () => {
    if (!accept) return;
    ///call enrollment method
    props.setLoading(true);
    trackEnrollment({ emailToken: emailToken });
    enroll(emailToken)
      .then(() => {
        props.setLoading(false);
        navigate("/confirmation");
      })
      .catch(() => {
        props.setLoading(false);
        navigate("/error");
      });
  };
  return (
    <div className={styles.enrollment} data-testid="enrollment">
      <span>
        <input
          type="checkbox"
          className={styles.termsCheckbox}
          data-testid="enrollment-checkbox"
          checked={accept}
          onChange={handleOnChange}
        />

        {
          <span
            tabIndex={0}
            className={"o-type-expressive-p  ml-4 mb-0 " + styles.acceptAll}
          >
            {checkBoxText} {parse(checkboxUrl)}
          </span>
        }
      </span>

      <button
        type="button"
        id="dt-1clickpaperlesssignup"
        aria-disabled={!accept}
        aria-describedby="disabledReason"
        onClick={signUp}
        data-testid="enrollment-button"
        className={
          styles.btn +
          " bx-btn bx-btn--primary mb-4 " +
          (accept ? "" : styles.disabled)
        }
      >
        {buttonText}
      </button>
    </div>
  );
}
