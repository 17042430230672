import React from "react";
import { Lighting, Fire } from "../../assets/icons/svg";
import AddressInfoModel from "../../models/AddressInfoModel";

export interface EnergyIconProps {
  address: AddressInfoModel;
  mode?: string;
  color: string;
}

export function EnergyIcon(props: EnergyIconProps) {
  require("./EnergyIcon.scss");
  const icon = (address: AddressInfoModel) => {
    const isElectric = address?.fuelType?.toLocaleLowerCase() === "electric";
    return isElectric ? <Lighting></Lighting> : <Fire></Fire>;
  };
  return (
    <span
      title={props.address?.fuelType?.toLocaleLowerCase()}
      className={
        "address-icon p-4 " +
        props.color +
        " " +
        props.address?.fuelType?.toLocaleLowerCase() +
        " " +
        props.mode
      }
      data-testid="address-icon"
    >
      {icon(props.address)}
    </span>
  );
}
