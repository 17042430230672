import "./scss/styles.scss";
import {
  getPaperlessContent,
  SitefinityContentProps,
} from "./SitefinityContent";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { MainLayout } from "./layouts/main/MainLayout";
import { Home } from "./views/home/Home";
import { Confirmation } from "./views/confirmation";
import { OptoutLayout } from "./layouts/main/OptoutLayout";
import { ErrorLayout } from "./layouts/main/ErrorLayout";
import { getAddressInfo } from "./api/UIApi";
import { AddressInfoModelList } from "./models/AddressInfoModel";
import {
  ReactPlugin,
  AppInsightsContext,
} from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { REACT_APP_APP_INSIGHTS_KEY, FF_OPT_OUT } from "./variables";
import { useFlags } from "launchdarkly-react-client-sdk";

export const App = (props: any) => {
  const [contextualMessage, setContextualMessage] = useState(
    {} as SitefinityContentProps
  );
  const [addresses, setAddresses] = useState([] as AddressInfoModelList);
  const [loading, setLoading] = useState(false);
  let reactPlugin = new ReactPlugin();
  
  const navigate = useNavigate()
  const ff_paperlessOptOut = useFlags()[FF_OPT_OUT] as boolean;
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const messages = await getPaperlessContent("");
      setContextualMessage(messages);
    })();

    let appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: REACT_APP_APP_INSIGHTS_KEY,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
      },
    });
    ;
    appInsights.loadAppInsights();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (props.url) {
          setLoading(true);
          const response = await getAddressInfo(
            props.url.substring(props.url.lastIndexOf("/") + 1)
          );
         

          setAddresses(response.data);
          /*let unenrolledAccounts =
          response.data === null || response.data.length === 0
            ? []
            : response.data.filter((c) => !c.enrolled);

          if (unenrolledAccounts && unenrolledAccounts.length === 0 && location?.pathname?.toLowerCase().includes("/home"))
            navigate("/confirmation");
          */
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [setAddresses, props.url, navigate]);

  const appRoutes = () => {
    return (
      <AppInsightsContext.Provider value={reactPlugin}>
        <Routes>
          <Route
            path="error"
            element={<ErrorLayout addresses={addresses} type="enrollment" />}
          />
          <Route
            path="errorDecline/:addresses"
            element={<ErrorLayout addresses={addresses} type="decline" />}
          />
          <Route
            path="decline/:addresses"
            element={<OptoutLayout loading={loading} ff_paperlessOptOut={ff_paperlessOptOut} setLoading={setLoading} />}
          />
          <Route
            path="/*"
            element={<MainLayout loading={loading} />}
          >
            <Route
              path="home/:emailToken"
              element={
                <Home
                  messages={contextualMessage}
                  addresses={addresses}
                  setLoading={setLoading}
                />
              }
            />
            <Route
              path="confirmation"
              element={<Confirmation addresses={addresses} />}
            />
          </Route>
        </Routes>
      </AppInsightsContext.Provider>
    );
  };

  return appRoutes();
};

export default App;
