import React from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Error } from "./../../views/error/Error";
import { AddressInfoModelList } from "../../models/AddressInfoModel";
export type ErrorLayoutProps = {  
  addresses: AddressInfoModelList;
  type: string;
};
export const ErrorLayout: React.FC<ErrorLayoutProps> = (
  props: ErrorLayoutProps
) => {
  return (
    <div className="error-wrapper">
      <Header></Header>
      <main className="error-main" data-testid="error-layout">
        <Error addresses={props.addresses} type={props.type} />
      </main>
      <Footer></Footer>
    </div>
  );
};
