import { REACT_APP_EVERSOURCE_URL } from "../../../variables";

type Props = {};

export const Footer: React.FC<Props> = () => {
  const terms =
    REACT_APP_EVERSOURCE_URL + "/content/residential/about/terms-and-conditions";
  const privacy =
    REACT_APP_EVERSOURCE_URL + "/content/residential/about/legal-statements";
  return (
    <footer className=" paperless-container" data-testid="footer">
      <div className="u-tri-color-lines--before"></div>
      <div className="os-container--max-width md:cms-key-intents__copy footer-text md:pt-8 pt-6">
        <span
          tabIndex={0}
          className="lg:mr-6 cms-key-intents__copy md:cms-flex-grid__col--12 copyright lg:p-0   pl-5 "
        >
          &copy;2023 Eversource Energy. All Rights Reserved.
        </span>
        <a
          href={terms}
          className="lg:mr-6 cms-key-intents__copy md:cms-flex-grid__col--6 lg:p-0 pl-8 pb-4 terms"
        >
          Terms & Conditions
        </a>
        <a
          href={privacy}
          className="mr-6 cms-key-intents__copy md:cms-flex-grid__col--6 lg:p-0 pl-6 pb-4 privacy"
        >
          Privacy & Legal Statements
        </a>
      </div>
    </footer>
  );
};
