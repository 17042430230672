import { AddressInfo } from "../../components/AddressInfo";
import { TextComponent } from "../../layouts/main/TextComponent";
import { SitefinityContentProps } from "../../SitefinityContent";
import { AddressInfoModelList } from "../../models/AddressInfoModel";
import { Enrollment } from "./../../components/Enrollment/Enrollment";

type HomeProps = {
  messages: SitefinityContentProps;
  addresses: AddressInfoModelList;
  setLoading: Function;
};

export function Home(props: HomeProps) {
  const title =
    "These accounts will all be enrolled in paperless when you sign up:";

  return (
    <>
      <TextComponent
        description={props.messages.mainDescription}
        title={props.messages.mainTitle}
      ></TextComponent>
      <div>
        <h2 tabIndex={0} className="o-heading-delta md:mb-8 mb-5">
          {title}
        </h2>
        <AddressInfo
          addresses={props.addresses}
          icon_color="blue"
        ></AddressInfo>

        <Enrollment
          addresses={props.addresses}
          setLoading={props.setLoading}
        ></Enrollment>
      </div>
    </>
  );
}
