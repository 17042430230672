import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Check } from "../../assets/icons/svg";
import { Ellipse1 } from "../../assets/icons/svg";
import { Ellipse2 } from "../../assets/icons/svg";
import { Ellipse3 } from "../../assets/icons/svg";
import { getPaperlessOptOutContent, SitefinityContentOptOutProps } from "../../SitefinityContent";
import { trackDecline } from "../../api/UIApi";
import "./optout-page.scss"

export type OptoutProps = {
  ff_paperlessOptOut: boolean;
  setLoading: Function;
};

export const Optout: React.FC<OptoutProps> = (props: OptoutProps) => {
  const messages: SitefinityContentOptOutProps = getPaperlessOptOutContent()
  const url = window.location.href
  const urlArr = url.split('/');
  const emailToken = urlArr[urlArr.indexOf('decline') + 1]
  const navigate = useNavigate();
  useEffect(() => {
    if (emailToken && props.ff_paperlessOptOut) {
      props.setLoading(true)
      const callTrackDecline = () => {
        trackDecline(emailToken)
        .then(response => {
          props.setLoading(false);
          if (response.status !== 200 && response.status !== 201) { 
            navigate(`/errorDecline/${emailToken}`);
          }
        })
        .catch(() => {
          props.setLoading(false);
          navigate(`/errorDecline/${emailToken}`);
        });
      }
      callTrackDecline();
    }
  }, [props.ff_paperlessOptOut])

  return (
    <div className="modal">
      {props.ff_paperlessOptOut ?
      <div className="optout-container">
        <div className="optout-component">
          <div className="header-sub-container">
            <div className="header">
              <div>
                <Check />
              </div>
              <h1 className="" tabIndex={0} data-testid="mainTitle">
                {messages.mainTitle}
              </h1>
            </div>
            <div>
              <h2 data-testid="mainDescription">
                {messages.mainDescription}
              </h2>
            </div>
            <div dangerouslySetInnerHTML={{ __html: messages.mainInformation }} className="text" data-testid="mainInformation" />
          </div>
        </div>
        <div className="bluebox-container">
          <div className="left-container">
            <h3 data-testid="boxedSubHead">
              {messages.boxedSubHead}
            </h3>
            <p dangerouslySetInnerHTML={{ __html: messages.boxedText }} className="text" data-testid="boxedText" />
          </div>
          <div className="list-container">
            <div className="list">
              <div className="iconContainer">
                <Ellipse1 />
              </div>
              <span dangerouslySetInnerHTML={{ __html: messages.step1 }} data-testid="step1" />
            </div>
            <div className="list">
              <div className="iconContainer">
                <Ellipse2 />
              </div>
              <span dangerouslySetInnerHTML={{ __html: messages.step2 }} data-testid="step2" />
            </div>
            <div className="list">
              <div className="iconContainer">
                <Ellipse3 />
              </div>
              <span dangerouslySetInnerHTML={{ __html: messages.step3 }} data-testid="step3" />
            </div>
          </div>
        </div>
      </div>
      : <div className="optout-container ffOff">
          <div className="header">
            <h1 className="" tabIndex={0} data-testid="mainTitle">This link has expired.</h1>
          </div>
          <p>
            Please sign in to <a href="https://www.eversource.com/cg/customer/myaccount" target="_blank" rel="noopener noreferrer">update your paperless settings</a>.
          </p>
        </div>}
    </div>
  );
};
