export type SitefinityContentProps = {
  mainTitle: string;
  mainDescription: string;
  mainInformation: string;
};

export type SitefinityContentOptOutProps = {
  mainTitle: string;
  mainDescription: string;
  mainInformation: string;
  boxedSubHead: string;
  boxedText: string;
  step1: string;
  step2: string;
  step3: string;
}

export const getPaperlessContent = async (view: string) => {
  let messagesArray: SitefinityContentProps;
  switch (view) {
    case "confirmation":
      messagesArray = {
        mainTitle: "Done! You're enrolled in paperless billing.",
        mainDescription: "You will stop getting paper bills for these accounts: ",
        mainInformation: "Paperless billing starts with your next billing cycle."      
      };
      break;
    default:
      messagesArray = {
        mainTitle: "Go Paperless",
        mainDescription: "Instead of receiving a paper bill in the mail each month you'll receive an email reminder to view your bill online",
        mainInformation: ""
      };
      break;
  }

  return messagesArray;
};

export const getPaperlessOptOutContent = () => {
  let messagesArray: SitefinityContentOptOutProps; 
  messagesArray = {
    mainTitle: "We Got It – You Will Continue with Paper Bills",
    mainDescription:
      "Did you know you can get a PDF of your bill emailed each month?",
    mainInformation:
      "<p>Some customers avoid paperless billing because they don't want to sign in to view their bill.</p><p>But with our PDF option, you can quickly open a password-protected PDF of your bill in your monthly statement email, no sign in required.</p>",
    boxedSubHead: "A seamless combination",
    boxedText:"Paperless PDF bills are the ideal companion to auto pay. Request this option in 3 easy steps.",
    step1: '<a href="https://www.eversource.com/cg/customer/myaccount" data-sf-ec-immutable="">Turn on paperless billing</a>',
    step2: '<a href="https://www.eversource.com/cg/customer/alerts" data-sf-ec-immutable="">Update your alert settings</a> under &lsquo;paperless billing options&rsquo;',
    step3: "Choose a password for the secure PDF attachment "
  };  
  return messagesArray;
};

