import { useEffect, useState } from "react";
import { Notification } from "../../components/Notification/Notification";
import { AddressInfo } from "../../components/AddressInfo";
import { AddressInfoModelList } from "../../models/AddressInfoModel";
import {
  getPaperlessContent,
  SitefinityContentProps,
} from "../../SitefinityContent";
import {
  REACT_APP_ALERTS_URL,
  REACT_APP_EVERSOURCE_URL,
} from "../../variables";
import "./Confirmation.scss";
import goToUrl from "../../utils/goToUrl";
export interface ConfirmationProps {
  addresses: AddressInfoModelList;
}
export function Confirmation(props: ConfirmationProps) {
  const alertsUrl = REACT_APP_EVERSOURCE_URL! + REACT_APP_ALERTS_URL;
  const [contextualMessages, setContextualMessages] = useState(
    {} as SitefinityContentProps
  );
  useEffect(() => {
    (async () => {
      const messages = await getPaperlessContent("confirmation");
      setContextualMessages(messages);
    })();
  }, []);

  return (
    <div className="confirmation-view" data-testid="confirmation">
      <h2 className="o-heading-beta mb-8" tabIndex={0}>
        {contextualMessages.mainTitle}
      </h2>
      <div className="placeholder">
        <h2 className="cms-type-intro-p mb-7 confirmation-text" tabIndex={0}>
          {contextualMessages.mainDescription}
        </h2>
        <AddressInfo
          mode="confirmation"
          addresses={props.addresses}
          icon_color="gray"
        ></AddressInfo>
        <Notification
          information={contextualMessages.mainInformation}
        ></Notification>
      </div>
      <hr />
      <div className="alert-wrapper p-8 lg:mr-8">
        <div className="cms-flex-grid os-flex--stack-mobile">
          <div className="cms-flex-grid__col--6">
            <h3 className="o-heading-gamma" tabIndex={0}>
              Manage Your Alert Settings
            </h3>
            <p className="o-body-beta" tabIndex={0}>
              Take a few minutes to review your Eversource account settings
              including alerts and notifications, email contacts, payment
              history and more.
            </p>
          </div>
          <div className="cms-flex-grid__col--6 u-display-flex ">
            <button
              id="dt-1clickpaperlesssignin"
              onClick={() => {
                goToUrl(alertsUrl);
              }}
              disabled={false}
              data-testid="confirmation-button"
              className="bx-btn bx-btn--primary btn-signin"
            >
              Sign Into Eversource
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
