import React from "react";

import styles from "./TextComponent.module.scss";
export type TextComponentProps = {
  title: string;
  description: string;
};

export const TextComponent: React.FC<TextComponentProps> = (
  props: TextComponentProps
) => {
  const title = (title: string) => {
    return (
      <h1
        tabIndex={0}
        data-testid="title"
        className="o-heading-alpha o-heading-alpha--underline"
      >
        {props.title}
      </h1>
    );
  };
  const description = (description: string) => {
    return (
      <p
        tabIndex={0}
        data-testid="description"
        className={"o-type-intro-p md:pr-4 " + styles.description}
      >
        {props.description}
      </p>
    );
  };
  return (
    <div>
      {props.title ? title(props.title) : ""}
      {props.description ? description(props.description) : ""}
    </div>
  );
};
